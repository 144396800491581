import React from 'react';
import { Routes, Route, Link, useParams } from 'react-router-dom';

import { useAuth } from '../../auth/AuthProvider';

import BranchHome from './BranchHome';
import Profile from '../Profile';
import BranchSettings from './BranchSettings';
import BranchTaskTests from './BranchTaskTests';
import PageSection from '../../components/PageSection';
import Overrides from './Overrides';
import UserProfileSegment from '../UserProfileSegment';
import BranchUsers from './BranchUsers';

const BranchApp = () => {
	let auth = useAuth();

	const { branchID } = useParams();

	let branch = auth.profile.branches?.find(b => b.id === branchID);

	return (
		<div>
			<PageSection>
				<h3>
					Branch Application 
					[Branch: <span style={{color: '#ff66ff'}}>{branch.name}</span>{auth.profile.branches.length > 1 ? (<span> (<Link to="/auth/selectbranch">Change</Link>)</span>) : null}]
					<UserProfileSegment />
				</h3>
			</PageSection>

			<PageSection>
				<p className="appNav">
					<li><Link to={`/${branchID}`}>Home</Link></li>
					<li><Link to={`/${branchID}/overrides`}>Overrides</Link></li>
					<li><Link to={`/${branchID}/settings`}>Settings</Link></li>
					<li><Link to={`/${branchID}/users`}>Users</Link></li>
					<li><Link to={`/${branchID}/tasks`}>Tasks</Link></li>
					<li>Branch '<span>{branch.name}</span>'</li>
				</p>
			</PageSection>

			<Routes>
				<Route path="/" element={<BranchHome branch={branch} />} />
				<Route path="/profile" element={<Profile branch={branch} />} />
				<Route path="/settings/*" element={<BranchSettings branch={branch} />} />
				<Route path="/overrides/*" element={<Overrides branch={branch} />} />
				<Route path="/users/*" element={<BranchUsers branch={branch} />} />
				<Route path="/tasks" element={<BranchTaskTests branch={branch} />} />
			</Routes>
		</div>
	);
};

export default BranchApp;