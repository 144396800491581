import React from 'react';
import { useParams } from 'react-router-dom';

import PageSection from '../../../../components/PageSection';

import { useAPI } from '../../../../api';
import PropEditor from '../../../../components/PropEditor';
import RawJsonDisplay from '../../../../components/RawJsonDisplay';

const BranchFirewallSettings = ({ branch }) => {
	let api = useAPI();

	let { branchID } = useParams();

	let [branchFirewall, setBranchFirewall] = React.useState({
		firewallID: null,
		firewallGroup: null
	});

	let [availableFirewalls, setAvailableFirewalls] = React.useState([]);
	let [availableFirewallGroups, setAvailableFirewallGroups] = React.useState([]);

	const loadBranchFirewall = () => {
		api.get(`/api/branch/${branchID}/firewall`)
		.then(json => setBranchFirewall(json));
	};

	const saveBranchFirewall = () => {
		api.post(`/api/branch/${branchID}/firewall`, branchFirewall)
		.then(json => {
			loadBranchFirewall();
		});
	};

	const saveBranchFirewallForce = () => {
		api.post(`/api/branch/${branchID}/firewall?force=true`, branchFirewall)
		.then(json => {
			loadBranchFirewall();
		});
	};

	const loadFirewalls = () => {
		api.get(`/api/branch/${branchID}/admin/firewalls`)
		.then(json => setAvailableFirewalls(json));
	};

	const loadFirewallGroups = () => {
		if (!branchFirewall.firewallID) {
			return setAvailableFirewallGroups([]);
		}

		api.get(`/api/branch/${branchID}/admin/firewalls/${branchFirewall.firewallID}/firewallgroups`)
		.then(json => setAvailableFirewallGroups(json));
	};

	React.useEffect(() => {
		loadFirewalls();
		loadBranchFirewall();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		loadFirewallGroups();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchFirewall]);

	return (
		<div>
			<PageSection level={3} title="Firewall">
				<PropEditor
					object={branchFirewall}
					props={[
						{ label: 'Firewall', key: 'firewallID', type: 'select', options: [{ value: null, label: '(none)' }].concat(availableFirewalls.map(firewall => ({ label: firewall.name, value: firewall.id }))) },
						{ label: 'Firewall Group', key: 'firewallGroup', type: 'select', options: [{ value: null, label: '(none)'}].concat(availableFirewallGroups.map(group => ({ label: group.name, value: group.id }))) },
					]}
					onChange={setBranchFirewall}
				/>

				<p>
					<button onClick={saveBranchFirewall}>Save Firewall</button>
					<button onClick={saveBranchFirewallForce}>Save Firewall (Force)</button>
				</p>
			</PageSection>

			<PageSection level={4} title="Raw Data">
				<RawJsonDisplay data={branchFirewall} />
			</PageSection>
		</div>
	);
}

export default BranchFirewallSettings;