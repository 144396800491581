import React from 'react';
import PageSection from '../../../../components/PageSection';
import { useAPI } from '../../../../api';
import { useParams } from 'react-router-dom';
import DataTable from '../../../../components/DataTable';

export default function BranchUserGroupSettings({ branch }) {
	const api = useAPI();

	const { branchID } = useParams();

	const [branchUserGroups, setBranchUserGroups] = React.useState([]);
	const [selectedUserGroups, setSelectedUserGroups] = React.useState([]);
	
	const loadBranchUserGroups = () => {
		api.get(`/api/branch/${branchID}/firewall/usergroups`)
		.then(json => setBranchUserGroups(json));
	}

	const selectUserGroup = (userGroup) => {
		setSelectedUserGroups([...selectedUserGroups, userGroup]);
	};

	const deselectUserGroup = (userGroup) => {
		setSelectedUserGroups(selectedUserGroups.filter(c => c.id !== userGroup.id));
	}

	const makeSelectedUserGroupsAvailable = () => {
		api.put(`/api/branch/${branchID}/firewall/usergroups/availability`, selectedUserGroups.reduce((acc, userGroup) => ({ ...acc, [userGroup.id]: 'available' }), {}))
		.then(json => loadBranchUserGroups());
	};

	const makeSelectedUserGroupsUnavailable = () => {
		api.put(`/api/branch/${branchID}/firewall/usergroups/availability`, selectedUserGroups.reduce((acc, userGroup) => ({ ...acc, [userGroup.id]: 'unavailable' }), {}))
		.then(json => loadBranchUserGroups());
	}

	React.useEffect(() => {
		loadBranchUserGroups();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<PageSection level={3} title="User Groups">
				<p>User Groups for branch '{branch.name}'</p>

				<p>
					<button onClick={makeSelectedUserGroupsAvailable}>Make Selected Available</button>
					<button onClick={makeSelectedUserGroupsUnavailable}>Make Selected Unavailable</button>
				</p>

				<DataTable
					columns={[
						{ 
							label: <input type="checkbox" checked={selectedUserGroups.length === branchUserGroups.length} onChange={() => selectedUserGroups.length === branchUserGroups.length ? setSelectedUserGroups([]) : setSelectedUserGroups(branchUserGroups)} />,
							key: 'id',
							render: (userGroup) => <input type="checkbox" checked={selectedUserGroups.some(c => c.id === userGroup.id)} onChange={() => selectedUserGroups.some(c => c.id === userGroup.id) ? deselectUserGroup(userGroup) : selectUserGroup(userGroup)} />
						},
						{ label: 'Name', key: 'name' },
						{ label: 'Availability', key: 'availability' }
					]}
					data={branchUserGroups}
				/>
			</PageSection>
		</div>
	);
}