import React from 'react';
import PageSection from '../../../../components/PageSection';
import { useAPI } from '../../../../api';
import { useParams } from 'react-router-dom';
import DataTable from '../../../../components/DataTable';

export default function BranchCategorySettings({ branch }) {
	const api = useAPI();

	const { branchID } = useParams();

	const [branchCategories, setBranchCategories] = React.useState([]);
	const [selectedCategories, setSelectedCategories] = React.useState([]);

	const loadBranchCategories = () => {
		api.get(`/api/branch/${branchID}/firewall/categories`)
		.then(json => setBranchCategories(json));
	}

	const selectCategory = (category) => {
		setSelectedCategories([...selectedCategories, category]);
	};

	const deselectCategory = (category) => {
		setSelectedCategories(selectedCategories.filter(c => c.id !== category.id));
	};

	const makeSelectedCategoriesAvailable = () => {
		api.put(`/api/branch/${branchID}/firewall/categories/availability`, selectedCategories.reduce((acc, category) => ({ ...acc, [category.id]: 'available' }), {}))
		.then(json => loadBranchCategories());
	};

	const makeSelectedCategoriesUnavailable = () => {
		api.put(`/api/branch/${branchID}/firewall/categories/availability`, selectedCategories.reduce((acc, category) => ({ ...acc, [category.id]: 'unavailable' }), {}))
		.then(json => loadBranchCategories());
	};

	React.useEffect(() => {
		loadBranchCategories();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<PageSection level={3} title="Categories">
				<p>Categories for branch '{branch.name}'</p>

				<p>
					<button onClick={makeSelectedCategoriesAvailable}>Make Selected Available</button>
					<button onClick={makeSelectedCategoriesUnavailable}>Make Selected Unavailable</button>
				</p>
				
				<DataTable
					columns={[
						{ 
							label: <input type="checkbox" checked={selectedCategories.length === branchCategories.length} onChange={() => selectedCategories.length === branchCategories.length ? setSelectedCategories([]) : setSelectedCategories(branchCategories)} />,
							key: 'id',
							render: (category) => <input type="checkbox" checked={selectedCategories.some(c => c.id === category.id)} onChange={() => selectedCategories.some(c => c.id === category.id) ? deselectCategory(category) : selectCategory(category)} />
						},
						{ label: 'ID', key: 'id' },
						{ label: 'Name', key: 'name' },
						{ label: 'Productivity', key: 'productivity' },
						{ label: 'Availability', key: 'availability' },
					]}
					data={branchCategories}
				/>

			</PageSection>
		</div>
	);
}