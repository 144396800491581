import React from 'react';
import PageSection from '../../../components/PageSection';
import { useAPI } from '../../../api';
import PropEditor, { secretProp } from '../../../components/PropEditor';
import RawJsonDisplay from '../../../components/RawJsonDisplay';

export default function SystemEmail() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const error = urlParams.get('error');

	if (error)
		window.history.replaceState({}, document.title, '/system/email');

	const api = useAPI();

	const [emailSettings, setEmailSettings] = React.useState({
		type: 'none',
		settings: {
			none: { settings: {}, secrets: {}, knownSecrets: [] },
			smtp: { settings: {}, secrets: {}, knownSecrets: [] },
			'google-workspace': { settings: {}, secrets: {}, knownSecrets: [] },
			'microsoft-365': { settings: {}, secrets: {}, knownSecrets: [] }
		}
	});

	const [emailSettingsError, ] = React.useState(error);

	const [viewingMailType, setViewingMailType] = React.useState('none');

	const [testEmailTo, setTestEmailTo] = React.useState('');

	const [emailEndpoints, setEmailEndpoints] = React.useState({
		connect: '',
		callback: ''
	});

	React.useEffect(() => {
		loadEmailSettings();

		api.get('/api/system/email/endpoints')
		.then(json => setEmailEndpoints(json));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateTypeSettings = (type, updatedSettings) => {
		let newSettings = { 
			...emailSettings.settings,
			[type]: { ...emailSettings.settings[type], ...updatedSettings }
		};
		
		setEmailSettings({ ...emailSettings, settings: newSettings });
	}

	const clearEmailSettings = () => {
		api.delete('/api/system/email')
		.then(loadEmailSettings);
	};

	const loadEmailSettings = () => {
		api.get('/api/system/email')
		.then(json => {
			setEmailSettings(json);

			if (viewingMailType === 'none')
				setViewingMailType(json.type);
		});
	};

	const saveEmailSettings = () => {
		return api.put(`/api/system/email/${viewingMailType}`, {
			settings: { ...emailSettings.settings[viewingMailType].settings },
			secrets: { ...emailSettings.settings[viewingMailType].secrets }
		})
		.then(loadEmailSettings);
	};

	const connectGoogleWorkspace = () => {
		saveEmailSettings()
		.then(() => {
			api.get('/api/system/email/google-workspace/connect')
			.then(json => window.location.href = json.redirect);
		});
	};

	const disconnectGoogleWorkspace = () => {
		api.post('/api/system/email/google-workspace/disconnect')
		.then(loadEmailSettings);
	};

	const connectMicrosoft365 = () => {
		saveEmailSettings()
		.then(() => {
			api.get('/api/system/email/microsoft-365/connect')
			.then(json => window.location.href = json.redirect);
		});
	};

	const disconnectMicrosoft365 = () => {
		api.post('/api/system/email/microsoft-365/disconnect')
		.then(loadEmailSettings);
	};

	const testEmailSettings = () => {
		api.post('/api/system/email/test', { to: testEmailTo })
		.then(() => alert('Test email sent'));
	}

	const typeNames = {
		none: 'None',
		smtp: 'SMTP',
		'google-workspace': 'Google Workspace',
		'microsoft-365': 'Microsoft 365'
	};

	return (
		<div>
			<PageSection title="Email" level={2} />

			<PageSection title="Email Type" level={3}>
				<p>
					Type: <select value={viewingMailType} onChange={e => setViewingMailType(e.target.value)}>
						{['none', 'smtp', 'google-workspace', 'microsoft-365'].map(type => (
							<option key={type} value={type}>{typeNames[type]}{emailSettings.type === type && ' (active)'}</option>
						))}
					</select>
				</p>
			</PageSection>

			<PageSection title="Email Settings" level={3}>
				{
					viewingMailType === 'none' &&
					<div>
						<button onClick={() => clearEmailSettings()}>Clear Email Settings</button>
					</div>
				}

				{
					viewingMailType === 'smtp' &&
					<PropEditor
						object={emailSettings.settings?.smtp}
						props={[
							{ context: 'settings', key: 'host', label: 'Host', type: 'string', placeholder: 'smtp.example.com' },
							{ context: 'settings', key: 'port', label: 'Port', type: 'number', defaultValue: 25 },
							{
								context: 'settings', key: 'encryption', label: 'Encryption', type: 'select',
								options: [{ value: 'none', label: 'None' }, { value: 'ssl', label: 'SSL' }, { value: 'starttls', label: 'STARTTLS' }]
							},
							{ context: 'settings', key: 'from', label: 'From', type: 'string' },
							{
								context: 'settings', key: 'auth', label: 'Auth Type', type: 'select',
								options: [{ value: 'none', label: 'None' }, { value: 'auto', label: 'Auto' }, { value: 'plain', label: 'Plain' }, { value: 'login', label: 'Login' }]
							},
							{ context: 'settings', key: 'user', label: 'User', type: 'string' },
							secretProp({ context: 'secrets', key: 'password', label: 'Pass', type: 'password' })
						]}
						onChange={updateTypeSettings.bind(null, 'smtp')}
					/>
				}

				{
					viewingMailType === 'google-workspace' &&
					<>
						<PropEditor
							object={emailSettings.settings?.['google-workspace']}
							props={[
								{ label: 'OAuth Redirect Settings', key: '', type: 'info' },
								{ context: 'settings', key: '__redirectURL', label: 'Redirect URL', type: 'string', size: 60, disabled: true, value: `${emailEndpoints?.['google-workspace']?.callback}` },
								{ label: 'OAuth Settings', key: '', type: 'info' },
								{ context: 'settings', key: 'clientID', label: 'Client ID', type: 'string' },
								secretProp({ context: 'secrets', key: 'clientSecret', label: 'Client Secret', type: 'string' }),
							]}
							onChange={updateTypeSettings.bind(null, 'google-workspace')}
						/>

						{
							emailSettings.settings?.['google-workspace']?.profile.connected 
							? <>
									<p>Connected as: {emailSettings.settings?.['google-workspace']?.profile.userEmail}</p>
									<p><button onClick={disconnectGoogleWorkspace}>Disconnect Google Workspace</button></p>
								</>
							: <>
								<p><button onClick={connectGoogleWorkspace}>Save and Connect Google Workspace</button></p>
							</>
						}
					</>
				}

				{
					viewingMailType === 'microsoft-365' &&
					<>
						<PropEditor
							object={emailSettings.settings?.['microsoft-365']}
							props={[
								{ label: 'OAuth Redirect Settings', key: '', type: 'info' },
								{ context: 'settings', key: '__redirectURL', label: 'Redirect URL', type: 'string', size: 60, disabled: true, value: `${emailEndpoints?.['microsoft-365']?.callback}` },
								{ label: 'OAuth Settings', key: '', type: 'info' },
								{ context: 'settings', key: 'tenantID', label: 'Tenant ID', type: 'string' },
								{ context: 'settings', key: 'clientID', label: 'Client ID', type: 'string' },
								secretProp({ context: 'secrets', key: 'clientSecret', label: 'Client Secret', type: 'string' })
							]}
							onChange={updateTypeSettings.bind(null, 'microsoft-365')}
						/>

						{
							emailSettings.settings?.['microsoft-365']?.profile.connected 
							? <>
									<p>Connected as: {emailSettings.settings?.['microsoft-365']?.profile.userDisplayName} ({emailSettings.settings?.['microsoft-365']?.profile.userEmail})</p>
									<p><button onClick={disconnectMicrosoft365}>Disconnect Microsoft 365</button></p>
								</>
							: <>
								<p><button onClick={connectMicrosoft365}>Save and Connect Microsoft 365</button></p>
							</>
						}
					</>
				}

				<p>
					{emailSettingsError && <span style={{ color: 'red' }}>{emailSettingsError}</span>}
				</p>

				<p>
					{viewingMailType !== 'none' && <button onClick={saveEmailSettings}>Save Email Settings</button>}
				</p>
			</PageSection>

			<PageSection title="Test Email" level={3}>
				<p>
					To: <input type="text" id="test-email-to" value={testEmailTo} onChange={e => setTestEmailTo(e.target.value)} />
				</p>

				<p>
					<button onClick={testEmailSettings}>Send Test Email</button>
				</p>
			</PageSection>

			<PageSection title="Raw Data" level={3}>
				<RawJsonDisplay data={emailSettings} />
			</PageSection>
		</div>
	);
}