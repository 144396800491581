import React from 'react';
import PageSection from '../../../../components/PageSection';
import { useAPI } from '../../../../api';
import { useParams } from 'react-router-dom';
import PropEditor from '../../../../components/PropEditor';
import RawJsonDisplay from '../../../../components/RawJsonDisplay';

export default function BranchPolicySettings({ branch }) {
	let api = useAPI();

	let { branchID } = useParams();

	let [branchFirewallSettings, setBranchFirewallSettings] = React.useState({
		policyTemplate: null,
		policyInsertAbove: null
	});

	let [availableFirewallPolicies, setAvailableFirewallPolicies] = React.useState([]);

	const loadBranchFirewallSettings = () => {
		api.get(`/api/branch/${branchID}/firewall/settings`)
		.then(json => setBranchFirewallSettings(json));
	};

	const saveBranchFirewallSettings = () => {
		api.post(`/api/branch/${branchID}/firewall/settings`, branchFirewallSettings)
		.then(json => loadBranchFirewallSettings());
	};

	const loadFirewallPolicies = () => {
		api.get(`/api/branch/${branchID}/firewall/policies`)
		.then(json => setAvailableFirewallPolicies(json));
	}

	React.useEffect(() => {
		loadFirewallPolicies();
		loadBranchFirewallSettings();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<PageSection level={3} title="Policies">
				<PropEditor
					object={branchFirewallSettings}
					props={[
						{ label: 'Template Policy', key: 'policyTemplate', type: 'select', options: [{ value: null, label: '(none)'}].concat(availableFirewallPolicies.map(policy => ({ label: policy.name, value: policy.id }))) },
						{ label: 'Insert Above Policy', key: 'policyInsertAbove', type: 'select', options: [{ value: null, label: '(none)'}].concat(availableFirewallPolicies.map(policy => ({ label: policy.name, value: policy.id }))) }
					]}
					onChange={setBranchFirewallSettings}
				/>

				<p>
					<button onClick={saveBranchFirewallSettings}>Save Policies</button>
				</p>
			</PageSection>
			
			<PageSection level={4} title="Raw Data">
				<RawJsonDisplay data={branchFirewallSettings} />
			</PageSection>
		</div>
	);
};