import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useAPI } from '../../api';

export default function AuthInvite() {
	const navigate = useNavigate();
	const api = useAPI();

	let searchParams = new URLSearchParams(window.location.search);
	const token = searchParams.get('token');

	const loginLocalForm = React.useRef(null);
	const loginGoogleForm = React.useRef(null);
	const loginSAMLForm = React.useRef(null);
	const loginLDAPForm = React.useRef(null);

	let [error, setError] = React.useState(null);
	let [success, setSuccess] = React.useState(null);

	let [inviteInfo, setInviteInfo] = React.useState(null);
	let [strategies, setStrategies] = React.useState([]);
	let [selectedStrategy, setSelectedStrategy] = React.useState(null);

	let [displayName, setDisplayName] = React.useState('');
	let [password, setPassword] = React.useState('');
	let [password2, setPassword2] = React.useState('');

	const username = inviteInfo ? inviteInfo.email : '';

	async function acceptInvite() {
		switch (selectedStrategy) {
			case 'local': {
				let json = await api.post(`/api/auth/invite/${token}/accept`, { name: displayName, password });

				if (json.error) {
					setError(json.error);
				} else {
					setError(null);
					setSuccess('Invite accepted! Logging in...');
					
					setTimeout(() => {
						loginLocalForm.current.submit();
					}, 1000);
				}
				break;
			}
			case 'google': loginGoogleForm.current.submit(); break;
			case 'saml': loginSAMLForm.current.submit(); break;
			case 'ldap': loginLDAPForm.current.submit(); break;
			default: navigate('/auth/login'); break;
		}
	};

	function loadInviteInfo() {
		api.get(`/api/auth/invite/${token}`)
		.then(json => {
			setInviteInfo(json);

			if (json.name) {
				setDisplayName(json.name);
			} else {
				setDisplayName(json.email);
			}
		});
	}

	function loadStrategies() {
		api.get('/api/auth/strategies')
		.then(json => {
			setStrategies(json);
		});
	}

	React.useEffect(() => {
		loadInviteInfo();
		loadStrategies();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="centeredContainer">
			<div>
				<h1>Welcome{inviteInfo ? `, ${inviteInfo.name || inviteInfo.email}` : ''}!</h1>

				<p>
					You have been invited to join the system. Please set up your account.
				</p>

				{error ? <div style={{color: "red"}}>{error}</div> : null}
				{success ? <div style={{color: "green"}}>{success}</div> : null}

				<div className="spacedFormGroup">
					<label>Email</label>
					<input type="text" disabled={true} value={username} />
				</div>

				<div className="spacedFormGroup">
					<label>Name</label>
					<input type="text" value={displayName} onChange={e => setDisplayName(e.target.value)} />
				</div>

				{ inviteInfo && strategies && !selectedStrategy && (
					<div>
						<div>
							<h2>Choose a Sign-In Method</h2>
							{strategies.map(strategy => (
								<div key={strategy}>
									<button onClick={() => setSelectedStrategy(strategy)}>{
										{
											'local': 'Use Username/Email/Password',
											'google': 'Login with Google OAuth',
											'saml': 'Login with SAML',
											'ldap': 'Login with LDAP'
										}[strategy]
									} ({strategy})</button>
								</div>
							))}
						</div>
					</div>
				)}

				{inviteInfo && strategies && selectedStrategy && (
					<div>
						{{
							"local": (
								<div>
									<div className="spacedFormGroup">
										<label>Password</label>
										<input type="password" value={password} onChange={e => setPassword(e.target.value)} />
									</div>

									<div className="spacedFormGroup">
										<label>Confirm Password</label>
										<input type="password" value={password2} onChange={e => setPassword2(e.target.value)} />
									</div>

									<div className="spacedFormGroup">
										<input type="button" value="Set Name and Password and Accept Invite" onClick={acceptInvite} />
									</div>

									<form ref={loginLocalForm} method="POST" action="/api/auth/login/local">
										<input type="hidden" name="username" value={username} />
										<input type="hidden" name="password" value={password} />
									</form>
								</div>
							),
							"google": (
								<div>
									<div className="spacedFormGroup">
										<input type="button" value="Sign in with Google" onClick={acceptInvite} />
									</div>

									<form ref={loginGoogleForm} method="POST" action="/api/auth/login/google">
										<input type="hidden" name="username" value={username} />
									</form>
								</div>
							),
							"saml": (
								<div>
									<div className="spacedFormGroup">
										<input type="button" value="Sign in with SAML" onClick={acceptInvite} />
									</div>

									<form ref={loginSAMLForm} method="POST" action="/api/auth/login/saml">
										<input type="hidden" name="username" value={username} />
									</form>
								</div>
							),
							"ldap": (
								<div>
									<div className="spacedFormGroup">
										<label>LDAP Password</label>
										<input type="password" value={password} onChange={e => setPassword(e.target.value)} />
									</div>

									<div className="spacedFormGroup">
										<input type="button" value="Login with LDAP" onClick={acceptInvite} />
									</div>

									<form ref={loginLDAPForm} method="POST" action="/api/auth/login/ldap">
										<input type="hidden" name="username" value={username} />
										<input type="hidden" name="password" value={password} />
									</form>
								</div>
							)
						}[selectedStrategy]}

						<hr />

						<div className="spacedFormGroup">
							<button onClick={() => setSelectedStrategy(null)}>Change Strategy</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}