import React from 'react';

import PageSection from '../../../components/PageSection';
import BranchFirewallSettings from './BranchFirewallSettings';
import { Link, Route, Routes } from 'react-router-dom';
import BranchPolicySettings from './BranchPolicySettings';
import BranchCategorySettings from './BranchCategorySettings';
import BranchUserGroupSettings from './BranchUserGroupSettings';

const BranchSettings = ({ branch }) => {
	return (
		<div>
			<PageSection level={2} title="Settings">
				<p>Settings for branch '{branch.name}'</p>
			</PageSection>

			<PageSection>
				<p className="appNav">
					<li><Link to={`/${branch.id}/settings/firewall`}>Firewall</Link></li>
					<li><Link to={`/${branch.id}/settings/policies`}>Policies</Link></li>
					<li><Link to={`/${branch.id}/settings/categories`}>Categories</Link></li>
					<li><Link to={`/${branch.id}/settings/usergroups`}>User Groups</Link></li>
				</p>
			</PageSection>

			<Routes>
				<Route path="/firewall" element={<BranchFirewallSettings branch={branch} />} />
				<Route path="/policies" element={<BranchPolicySettings branch={branch} />} />
				<Route path="/categories" element={<BranchCategorySettings branch={branch} />} />
				<Route path="/usergroups" element={<BranchUserGroupSettings branch={branch} />} />
			</Routes>

			
		</div>
	);
};

export default BranchSettings;