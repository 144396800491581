import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

export default function RedirectRoutes() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const error = urlParams.get('error');

	return (
		<Routes>
			<Route path="/emailsettings/*" element={<Navigate to={error ? `/system/email?error=${encodeURIComponent(error)}` : `/system/email`} />} />
		</Routes>
	);
}