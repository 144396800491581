export function getPropByPath(object, path) {
	if (!object)
		return undefined;

	if (path === undefined)
		return object;

	if (path === '.' || path === '' || path === 'this')
		return object;

	if (path.includes('.')) {
		let parts = path.split('.');
		let value = object;

		for (let part of parts) {
			if (value === undefined)
				break;

			value = value[part];
		}

		return value;
	}

	return object[path];
};

export function setPropByPath(object, path, value) {
	if (path === undefined)
		return;

	if (path === '.' || path === '' || path === 'this')
		return;

	if (path.includes('.')) {
		let parts = path.split('.');
		let target = object;

		for (let i = 0; i < parts.length - 1; i++) {
			if (target[parts[i]] === undefined)
				target[parts[i]] = {};

			target = target[parts[i]];
		}

		target[parts[parts.length - 1]] = value;
	} else {
		object[path] = value;
	}
};
