import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';

import Home from './SystemHome';
import Profile from '../Profile';
import SystemBranches from './SystemBranches';
import SystemUsers from './SystemUsers';
import SystemTasks from './SystemTasks';
import PageSection from '../../components/PageSection';
import SystemAuthSettings from './SystemAuthSettings';
import SystemAccessSettings from './SystemAccessSettings';
import SystemFirewalls from './SystemFirewalls';
import UserProfileSegment from '../UserProfileSegment';
import SystemEmail from './SystemEmail';

const SystemApp = () => {
	return (
		<div>
			<PageSection title="Fastvue Platform Dev UI | System Manager" level={1} />

			<PageSection>
				<p className="appNav">
					<li><Link to="/system">Home</Link></li>
					<li><Link to="/system/branches">Branches</Link></li>
					<li><Link to="/system/auth">Authentication</Link></li>
					<li><Link to="/system/access">Access</Link></li>
					<li><Link to="/system/firewalls">Firewalls</Link></li>
					<li><Link to="/system/email">Email</Link></li>
					<li><Link to="/system/users">Users</Link></li>
					<li><Link to="/system/tasks">Tasks</Link></li>
					<li><UserProfileSegment /></li>
				</p>
			</PageSection>

			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/profile" element={<Profile />} />
				<Route path="/tasks" element={<SystemTasks />} />

				<Route path="/branches/*" element={<SystemBranches />} />
				<Route path="/users/*" element={<SystemUsers />} />
				<Route path="/auth/*" element={<SystemAuthSettings />} />
				<Route path="/access/*" element={<SystemAccessSettings />} />
				<Route path="/firewalls/*" element={<SystemFirewalls />} />
				<Route path="/email/*" element={<SystemEmail />} />
			</Routes>
		</div>
	);
};

export default SystemApp;